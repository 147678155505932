"use client";
import "../Assets/Css/HomeCards.css";
import t from "@/app/hooks/lang";
import { useState, useEffect } from "react";
import Image from "next/image";
import CardsData from "../Assets/Data/CardsData";
import HomeCard from "../../../Domain/HomeCard";
import ArrowButtons from "@/Core/Shared/ArrowButtons/Infrastructure/Ui/Components/ArrowButtons";

interface Props {
  lang: string;
}

const HomeCards = ({ lang }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1200px)");
    setIsMobile(mediaQuery.matches);
    const handler = (event: MediaQueryListEvent) => setIsMobile(event.matches);
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, []);

  const back = (): void => {
    if (selected > 0) {
      setSelected(selected - 1);
      scroll();
    }
  };

  const next = (): void => {
    if (selected < CardsData.length - 1) {
      setSelected(selected + 1);
      scroll();
    }
  };

  const scroll = (): void => {
    const element = document.querySelector(".home-card");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const getData = (): HomeCard[] => {
    return isMobile ? CardsData.slice(selected, selected + 1) : CardsData;
  };

  return (
    <div className="home-cards-container">
      <div className="container">
        <h2>{t("circularityAndWasteApproach", lang)}</h2>
        <div className="row">
          {getData().map((card: HomeCard, i: number) => (
            <div key={i} className="col-xl-4 col-12">
              <div className={`home-card ${card.className}`}>
                <div>
                  <h3>{t(card.title, lang)}</h3>
                  {card.description && <p>{t(card.description, lang)}</p>}
                </div>
                {card.list && (
                  <ul>
                    {card.list.map((item: string, e: number) => (
                      <li key={e}>{t(item, lang)}</li>
                    ))}
                  </ul>
                )}
                <Image src={card.img} alt={t(card.title, lang)} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {isMobile && (
        <ArrowButtons
          selected={selected}
          max={CardsData.length - 1}
          next={next}
          back={back}
          lang={lang}
        />
      )}
    </div>
  );
};

export default HomeCards;
