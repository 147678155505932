"use client";
import "../Assets/Css/HomeLogosSlider.css";
import t from "@/app/hooks/lang";
import { StaticImageData } from "next/image";
import Image from "next/image";

interface Props {
  title: string;
  images: StaticImageData[];
  lang: string;
  velocity?: number;
}

const HomeBannerLogos = ({ title, images, lang, velocity }: Props) => {
  const logosCount = images.length;
  const animationDuration = velocity ?? 2 * logosCount;
  return (
    <div className="home-banner-logos">
      <div className="container">
        <h2>{t(title, lang)}</h2>
        <div className="home-logos-slider">
          <div className="logos-marquee">
            <div
              className="logos-track"
              style={{ animationDuration: `${animationDuration}s` }}
            >
              {images.map((logo: StaticImageData, i: number) => (
                <div className="logo-item" key={i}>
                  <Image
                    src={logo}
                    alt={`Logo ${i}`}
                    height={80}
                    placeholder="blur"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeBannerLogos;
