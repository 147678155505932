"use client";
import t from "@/app/hooks/lang";
import Image from "next/image";
import ArrowLeft from "@/app/assets/images/arrows/arrow-left.svg";
import ArrowRight from "@/app/assets/images/arrows/arrow-right.svg";
import ArrowLeftActive from "@/app/assets/images/arrows/arrow-left-active.svg";
import ArrowRightActive from "@/app/assets/images/arrows/arrow-right-active.svg";

interface Props {
  selected: number;
  max: number;
  back: () => void;
  next: () => void;
  lang: string;
}

const ArrowButtons = ({ selected, max, back, next, lang }: Props) => {
  return (
    <div className="home-cards-buttons">
      <button onClick={() => back()}>
        <Image
          src={selected > 0 ? ArrowLeftActive : ArrowLeft}
          alt={t("back", lang)}
        />
      </button>
      <button onClick={() => next()}>
        <Image
          src={selected !== max ? ArrowRightActive : ArrowRight}
          alt={t("next", lang)}
        />
      </button>
    </div>
  );
};

export default ArrowButtons;
