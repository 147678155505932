"use client";
import { useEffect, useState } from "react";
import HubspotForm from "react-hubspot-form";

const LandingForm = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null; // Ensures component is only rendered on the client
  }

  const submitForm = () => {
    // * All of this is to send the event to GTM
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        event: "form_info_2",
        country: true,
      });
    }
    // * End of GTM event
  };

  return (
    <HubspotForm
      portalId="20183177"
      formId="b68609e1-714b-4b7c-8c28-50e442583c63"
      onSubmit={() => submitForm()}
      // loading={<div className="loading-spinner-medium" />}
    />
  );
};

export default LandingForm;
