import HomeSuccessStory from "@/Core/Marketplace/Home/Domain/HomeSuccessStory";
import Pyratex from "../Images/SuccessStories/Pyratex.webp";
import Pronovias from "../Images/SuccessStories/Pronovias.webp";
import Manifutura from "../Images/SuccessStories/Manifutura.webp";

const SuccessStoriesData: HomeSuccessStory[] = [
  {
    title: "PYRATEX",
    img: Pyratex,
    desc: "pyratexDesc",
    list: ["resaleSurplus", "deadstocksChannel", "digitalizationInter"],
  },
  {
    title: "MANIFUTURA",
    img: Manifutura,
    desc: "manifaturaDesc",
    list: ["resaleSurplus", "onlineSale", "sampleManagement"],
  },
  {
    title: "PRONOVIAS",
    img: Pronovias,
    desc: "pronoviasDesc",
    list: ["resaleStocks", "impactSaved", "traceabilityConnections"],
  },
];

export default SuccessStoriesData;
