"use client";
import "../Assets/Css/HomeSuccessStories.css";
import { useState, useEffect } from "react";
import t from "@/app/hooks/lang";
import Image from "next/image";
import SuccessStoriesData from "../Assets/Data/SuccessStoriesData";
import Tick from "@/app/assets/images/tick.svg";
import { useMediaQuery } from "react-responsive";
import ArrowButtons from "@/Core/Shared/ArrowButtons/Infrastructure/Ui/Components/ArrowButtons";
import HomeSuccessStory from "../../../Domain/HomeSuccessStory";

interface Props {
  lang: string;
}

const HomeSuccessStories = ({ lang }: Props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 990px)" });
  const itemsPerPage = isMobile ? 1 : 3;
  const [selected, setSelected] = useState<number>(0);
  const [visibleData, setVisibleData] = useState<HomeSuccessStory[]>([]);

  useEffect(() => {
    if (selected > SuccessStoriesData.length - itemsPerPage) {
      setSelected(SuccessStoriesData.length - itemsPerPage);
    }

    setVisibleData(getData(selected, itemsPerPage));
  }, [selected, isMobile]);

  const getData = (
    selectedIndex: number,
    limit: number
  ): HomeSuccessStory[] => {
    return SuccessStoriesData.slice(selectedIndex, selectedIndex + limit);
  };

  const back = (): void => {
    if (selected > 0) {
      setSelected(selected - 1);
      scroll();
    }
  };

  const next = (): void => {
    if (selected < SuccessStoriesData.length - itemsPerPage) {
      setSelected(selected + 1);
      scroll();
    }
  };

  const scroll = (): void => {
    const element = document.querySelector(".home-success-stories");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home-success-stories">
      <div className="container">
        <h2>{t("successStories", lang).toUpperCase()}</h2>
        <div className="row">
          {visibleData.map((success: HomeSuccessStory, i: number) => (
            <div key={i} className="col-lg-4 col-12">
              <div className="home-success-stories-item">
                <div className="home-success-img">
                  <Image src={success.img} alt="success story" />
                </div>
                <div>
                  <h3>{success.title}</h3>
                  <p>“{t(success.desc, lang)}.”</p>
                  <ul>
                    {success.list.map((item: string, i: number) => (
                      <li key={i}>
                        <Image src={Tick} alt="Tick" width={20} height={20} />
                        <p>{t(item, lang)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isMobile && (
          <ArrowButtons
            selected={selected}
            back={back}
            next={next}
            lang={lang}
            max={SuccessStoriesData.length - itemsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default HomeSuccessStories;
