import HomeOurService from "@/Core/Marketplace/Home/Domain/HomeOurService";
import Service1 from "../Images/OurServices/Service1.webp";
import Service2 from "../Images/OurServices/Service2.webp";
import Service3 from "../Images/OurServices/Service3.webp";
import Service4 from "../Images/OurServices/Service4.webp";

const HomeOurServicesData: HomeOurService[] = [
  {
    img: Service1,
    values: [
      "ourServiceslargeFabricsStock",
      "ourServicesQuickDelivery",
      "ourServicesAfforadablePricing",
      "ourServicesFlexibleOptions",
      "ourServicesFilterByStock",
      "ourServicesCMT",
      "ourServicesDigitalPassport",
    ],
    buttonTxt: "buyFabrics",
    buttonLink: "/c/shop",
  },
  {
    img: Service2,
    values: [
      "ourServicesEaslySell",
      "ourServicesEarnUp",
      "ourServicesEnsureTraceability",
      "ourServicesMeasure",
    ],
    buttonTxt: "bookDemo",
    buttonLink: "https://calendly.com/team-recovo/book-a-demo",
  },
  {
    img: Service3,
    values: [
      "ourServicesIntrouce",
      "ourServicesSaveMoney",
      "wasteCo2",
      "ourServicesLegislations",
    ],
    buttonTxt: "bookDemo",
    buttonLink: "https://calendly.com/team-recovo/book-a-demo",
  },
  {
    img: Service4,
    values: [
      "ourServicesConnectStocks",
      "ourServicesEnsureTraceability2",
      "measureCo2",
      "complyLegislations",
    ],
    buttonTxt: "bookDemo",
    buttonLink: "https://calendly.com/team-recovo/book-a-demo",
  },
];

export default HomeOurServicesData;
