"use client";
import "../Assets/Css/HomeOurServices.css";
import t from "@/app/hooks/lang";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import Steps from "../Assets/Data/HomeOurServicesSteps.json";
import Data from "../Assets/Data/HomeOurServicesData";
import Image from "next/image";
import Arrow from "@/app/assets/images/arrows/arrow-right-white.svg";
import ArrowButtons from "@/Core/Shared/ArrowButtons/Infrastructure/Ui/Components/ArrowButtons";
import { useMediaQuery } from "react-responsive";

interface Props {
  lang: string;
}

const HomeOurServices = ({ lang }: Props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 990px)" });
  const [stepSelected, setStepSelected] = useState<number>(0);
  const stepRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  const hasMounted = useRef(false);

  const back = useCallback((): void => {
    setStepSelected((prev) => (prev > 0 ? prev - 1 : prev));
  }, []);

  const next = useCallback((): void => {
    setStepSelected((prev) => (prev < Steps.length - 1 ? prev + 1 : prev));
  }, []);

  const handleClick = (index: number) => {
    setStepSelected(index);
    stepRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  const renderServices = useMemo(() => {
    return Data[stepSelected].values.map((item: string, i: number) => (
      <div key={i} className="service">
        <div className="arrow-image">
          <Image src={Arrow} alt="arrow" />
        </div>
        <p dangerouslySetInnerHTML={{ __html: t(item, lang) }} />
      </div>
    ));
  }, [stepSelected, lang]);

  const Header = () => {
    const memoizedSteps = useMemo(() => {
      return Steps.map((step: string, i: number) => (
        <p
          key={i}
          ref={(el) => {
            stepRefs.current[i] = el;
          }}
          onClick={() => handleClick(i)}
          className={stepSelected === i ? "selected" : ""}
        >
          {i + 1}. {t(step, lang)}
        </p>
      ));
    }, [Steps, lang, stepSelected]);

    useEffect(() => {
      if (hasMounted.current) {
        stepRefs.current[stepSelected]?.scrollIntoView({
          behavior: "instant",
          block: "nearest",
          inline: "center",
        });
      } else {
        hasMounted.current = true;
      }
    }, [stepSelected]);

    return (
      <div className="home-services-header">
        {!isMobile && <h2>{t("ourServices", lang)}</h2>}
        <div className="home-services-header-content">{memoizedSteps}</div>
      </div>
    );
  };

  const ServiceInfo = () => (
    <div className="col-lg-7 col-12">
      <h3 className="home-step-title">
        {stepSelected + 1}. {t(Steps[stepSelected], lang)}
      </h3>
      <div>{renderServices}</div>
      <a href={Data[stepSelected].buttonLink}>
        <button className="home-service-info-btn">
          {t(Data[stepSelected].buttonTxt, lang).toUpperCase()}
        </button>
      </a>
      {isMobile && (
        <div className="home-service-arrows-mobile">
          <ArrowButtons
            selected={stepSelected}
            max={Steps.length - 1}
            next={next}
            back={back}
            lang={lang}
          />
        </div>
      )}
    </div>
  );

  const ServiceImage = () => (
    <div className="col-3 d-none d-lg-block relative">
      <div className="home-service-img">
        <Image
          className="home-service-img-element"
          src={Data[stepSelected].img}
          alt="service"
          placeholder="blur"
        />
        <ArrowButtons
          selected={stepSelected}
          max={Steps.length - 1}
          next={next}
          back={back}
          lang={lang}
        />
      </div>
    </div>
  );

  return (
    <div className="home-our-services-container">
      <div className="container">
        {isMobile && (
          <h2 className="title-header-mobile">{t("ourServices", lang)}</h2>
        )}
        <Header />
        <div className="home-services-body">
          <div className="row">
            <div className="col-1 d-none d-lg-block" />
            <ServiceInfo />
            <ServiceImage />
            <div className="col-1 d-none d-lg-block" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOurServices;
