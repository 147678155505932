import HomeCard from "@/Core/Marketplace/Home/Domain/HomeCard";
import DashboardMobile from "../Images/Cards/DashboardMobile.webp";
import HomeIpad from "../Images/Cards/HomeIpad.webp";
import HomeIpad2 from "../Images/Cards/HomeIpad2.webp";

const CardsData: HomeCard[] = [
  {
    title: "takeActionManagment",
    img: HomeIpad,
    className: "home-card-unpair",
    list: ["cardList", "cardList2", "cardList3"],
  },
  {
    title: "trackEmissionWaste",
    className: "home-card-pair",
    img: DashboardMobile,
  },
  {
    title: "ResportSustainabilityRegulations",
    className: "home-card-unpair home-card-unpair-last",
    img: HomeIpad2,
    description: "trackManageWasteDesc",
  },
];

export default CardsData;
