import Locales from "../assets/data/Locales.json";
import Cookies from "js-cookie";

export default function Lang(type: string, lang?: string): string {
  if (!lang) lang = Cookies.get("language") ?? "en";
  const data = Locales.filter((item) => item.key == type);
  if (data.length > 0) {
    switch (lang) {
      case "es":
        return data[0].es ?? "";
      case "en":
        return data[0].en ?? "";
      case "fr":
        return data[0].fr ?? "";
      case "nl":
        return data[0].nl ?? "";
      case "it":
        return data[0].it ?? "";
      case "pt":
        return data[0].pt ?? "";
      case "de":
        return data[0].de ?? "";
      default:
        if (data[0].en) return data[0].en ?? "";
        else return "";
    }
  } else {
    return type ?? "";
  }
}
